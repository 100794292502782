import { AsyncPipe, NgClass } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  DestroyRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
  effect,
  input,
  viewChild,
  viewChildren,
} from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { AbstractControl } from '@angular/forms';
import { MatAutocomplete, MatAutocompleteModule, MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { MatOption } from '@angular/material/core';
import { TranslateModule } from '@ngx-translate/core';
import { debounceTime, filter, startWith } from 'rxjs/operators';
import { Option } from './option.interface';

const AUTOCOMPLETE_DEBOUNCE_TIME = 300;

@Component({
  selector: 'myt-autocomplete',
  standalone: true,
  imports: [MatAutocompleteModule, NgClass, TranslateModule, AsyncPipe],
  templateUrl: './autocomplete.component.html',
  styleUrls: ['./autocomplete.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AutocompleteComponent implements OnInit {
  // An instance of autocomplete enables the passing of data to the input component.
  readonly autocomplete = viewChild(MatAutocomplete);
  readonly matOptions = viewChildren(MatOption);

  readonly control = input.required<AbstractControl>();
  readonly loading = input<boolean>(false);
  readonly hideDropdown = input<boolean>();
  readonly options = input.required<Option[]>();
  readonly multiSelect = input<boolean>(false);
  readonly selectedOptions = input<Option[]>();

  readonly markSelectedOptionsEffect = effect(() => {
    const selectedOptions = this.selectedOptions();
    const matOptions = this.matOptions();

    if (this.multiSelect() && matOptions?.length) {
      matOptions.forEach((matOption: MatOption) => {
        selectedOptions?.some((opt) => opt.value === matOption.value?.value) ? matOption.select(false) : matOption.deselect(false);
      });
    }
  });

  @Input() allowEmptySearch = true;

  @Output() selectOption = new EventEmitter<Option>();
  @Output() valueChanges = new EventEmitter<string>();

  ngOnInit(): void {
    this.setOptions();
  }

  constructor(private readonly destroyRef: DestroyRef) {}

  emitSelectOption(event: MatAutocompleteSelectedEvent): void {
    this.selectOption.emit(event.option.value);
  }

  displayWith(option: Option): string {
    return option?.title;
  }

  private setOptions(): void {
    this.control()
      .valueChanges.pipe(
        takeUntilDestroyed(this.destroyRef),
        debounceTime(AUTOCOMPLETE_DEBOUNCE_TIME),
        filter((result: string) => typeof result === 'string'),
        startWith(''),
      )
      .subscribe((searchQuery: string) => {
        if (!this.allowEmptySearch && searchQuery === '') {
          return;
        }

        this.valueChanges.emit(searchQuery);
      });
  }
}
